import React, { useRef,useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import VideoPopup from "../../common/Modals/VideoPopup";
import BlogSidebar from "./BlogSidebar";

const settings = {
  autoplay: true,
  autoplaySpeed: 4000,
  dots: false,
  fade: false,
  arrows: false,
};

const BlogPost = () => {
  const sliderRef = useRef(null);
  const [isVideoOpen,setIsVideoOpen] = useState(false);
  return (
    <>
      <div className="postbox__area pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="postbox__wrapper mr-40">


              <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb w-img">
                    <Link to="/find-trusted-xylem-distributors-for-water-solutions">
                      <img src="/assets/img/blog/xylem-pumps-distributors-dubai.jpg" alt="Xylem Distributors" title="Xylem Distributors"/>
                    </Link>
                  </div>
                  <div className="postbox__content">
                    <div className="postbox__meta mb-25">
                      <span>
                        <i className="fal fa-calendar-day"></i>APRIL 03, 2025
                      </span>
                    </div>
                    <h3 className="postbox__title">
                      <Link to="/find-trusted-xylem-distributors-for-water-solutions">
                      Where to Find Reliable Xylem Distributors for Water Technology Solutions?
                      </Link>
                    </h3>
                    <div className="postbox__text">
                      <p>
                      Water management is one of the strong concerns that can be easily considered with modern technological development. The foundational aspect of it is never to be neglected. It needs to be fixed with […]
                      </p>
                    </div>
                    <div className="postbox__read-more">
                      <Link className="tp-btn-border" to="/find-trusted-xylem-distributors-for-water-solutions">
                        read more
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                </article>  
   


              <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb w-img">
                    <Link to="/why-xylem-pumps-best-for-water-treatment">
                      <img src="/assets/img/blog/xylem-pumps-water-treatment.jpg" alt="Xylem Pumps Distributors" title="Xylem Pumps Distributors"/>
                    </Link>
                  </div>
                  <div className="postbox__content">
                    <div className="postbox__meta mb-25">
                      <span>
                        <i className="fal fa-calendar-day"></i>MARCH 22, 2025
                      </span>
                    </div>
                    <h3 className="postbox__title">
                      <Link to="/why-xylem-pumps-best-for-water-treatment">
                      Why Xylem Pumps Are the Preferred Choice for Water Treatment Plants
                      </Link>
                    </h3>
                    <div className="postbox__text">
                      <p>
                      Sanitation and Water Treatment Plants are considered to be the foundational workflow of any landscape. It should be planned in such a way that it can be a reliable source […]
                      </p>
                    </div>
                    <div className="postbox__read-more">
                      <Link className="tp-btn-border" to="/why-xylem-pumps-best-for-water-treatment">
                        read more
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                </article>  


              <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb w-img">
                    <Link to="/Tsurumi-Submersible-Pumps-Reliable-for-Tough-Jobs">
                      <img src="/assets/img/blog/Tsurumi-submersible-pumps.jpg" alt="tsurumi submersible pump, tsurumi pump distributors, tsurumi pump, tsurumi pump dealers" title="tsurumi submersible pump"/>
                    </Link>
                  </div>
                  <div className="postbox__content">
                    <div className="postbox__meta mb-25">
                      <span>
                        <i className="fal fa-calendar-day"></i>MARCH 22, 2025
                      </span>
                    </div>
                    <h3 className="postbox__title">
                      <Link to="/Tsurumi-Submersible-Pumps-Reliable-for-Tough-Jobs">
                      Tsurumi Submersible Pumps: A Reliable Solution for Tough Environments
                      </Link>
                    </h3>
                    <div className="postbox__text">
                      <p>
                      In industries where water management is important, submersible pumps play a vital role in ensuring smooth operations. From construction sites and mining […]
                      </p>
                    </div>
                    <div className="postbox__read-more">
                      <Link className="tp-btn-border" to="/Tsurumi-Submersible-Pumps-Reliable-for-Tough-Jobs">
                        read more
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                </article>



              <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb w-img">
                    <Link to="/Why-Tsurumi-Pump-Distributors-Lead-in-Industry-Use">
                      <img src="/assets/img/blog/tsurumi-pump-distributors.jpg" alt="tsurumi pump distributors, tsurumi pump, tsurumi pump dealers,tsurumi submersible pump" title="tsurumi pump distributors"/>
                    </Link>
                  </div>
                  <div className="postbox__content">
                    <div className="postbox__meta mb-25">
                      <span>
                        <i className="fal fa-calendar-day"></i>MARCH 18, 2025
                      </span>
                    </div>
                    <h3 className="postbox__title">
                      <Link to="/Why-Tsurumi-Pump-Distributors-Lead-in-Industry-Use">
                      Why Are Tsurumi Pump Distributors the First Choice for Industrial Applications?
                      </Link>
                    </h3>
                    <div className="postbox__text">
                      <p>
                      The submersible pumps are the industrial standard pumps that are more convenient than the rest of them. In that consideration, the Tsurumi Submersible Pumps are getting deserved recognition for this wonderful  […]
                      </p>
                    </div>
                    <div className="postbox__read-more">
                      <Link className="tp-btn-border" to="/Why-Tsurumi-Pump-Distributors-Lead-in-Industry-Use">
                        read more
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                </article>


                <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb w-img">
                    <Link to="/Tsurumi-Submersible-Pump-Excel-in-Heavy-Duty-Use">
                      <img src="/assets/img/blog/tsurumi-submersible-pump.jpg" alt="tsurumi submersible pump, tsurumi pump distributors, tsurumi pump dealers,tsurumi pump" title="tsurumi submersible pump"/>
                    </Link>
                  </div>
                  <div className="postbox__content">
                    <div className="postbox__meta mb-25">
                      <span>
                        <i className="fal fa-calendar-day"></i>MARCH 15, 2025
                      </span>
                    </div>
                    <h3 className="postbox__title">
                      <Link to="/Tsurumi-Submersible-Pump-Excel-in-Heavy-Duty-Use">
                      What Makes Tsurumi Submersible Pumps Ideal for Heavy-Duty Applications?
                      </Link>
                    </h3>
                    <div className="postbox__text">
                      <p>
                      The wide application of submersible pumps in any industry has its own beneficial claims. They are mechanisms that are capable of withstanding heavy-duty applications. In industrial operations, submersible pumps are very useful in the field of sewage management. They are ideal in […]
                      </p>
                    </div>
                    <div className="postbox__read-more">
                      <Link className="tp-btn-border" to="/Tsurumi-Submersible-Pump-Excel-in-Heavy-Duty-Use">
                        read more
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                </article>


                <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb w-img">
                    <Link to="/Why-Xylem-Pumps-Are-Ideal-for-Municipal-Water-Project">
                      <img src="/assets/img/blog/xylem-pumps.jpg" alt="xylem pumps, xylem pumps distributors,xylem distributors" title="xylem pumps"/>
                    </Link>
                  </div>
                  <div className="postbox__content">
                    <div className="postbox__meta mb-25">
                      <span>
                        <i className="fal fa-calendar-day"></i>MARCH 15, 2025
                      </span>
                    </div>
                    <h3 className="postbox__title">
                      <Link to="/Why-Xylem-Pumps-Are-Ideal-for-Municipal-Water-Project">
                      Why Are Xylem Pumps Preferred for Municipal Water Projects? 
                      </Link>
                    </h3>
                    <div className="postbox__text">
                      <p>
                      Municipal water projects require reliable, efficient, and high-performing pumping solutions to ensure a seamless supply of clean water to communities. Among the diverse brands available, Xylem pumps have emerged as […]
                      </p>
                    </div>
                    <div className="postbox__read-more">
                      <Link className="tp-btn-border" to="/Why-Xylem-Pumps-Are-Ideal-for-Municipal-Water-Project">
                        read more
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                </article>


                <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb w-img">
                    <Link to="/Boost-Energy-Efficiency-with-Xylem-Pumps">
                      <img src="/assets/img/blog/Boost-Energy-Efficiency-with-Xylem-Pumps.jpg" alt="xylem pumps, xylem pumps distributors,xylem distributors" title="xylem pumps"/>
                    </Link>
                  </div>
                  <div className="postbox__content">
                    <div className="postbox__meta mb-25">
                      <span>
                        <i className="fal fa-calendar-day"></i>MARCH 15, 2025
                      </span>
                    </div>
                    <h3 className="postbox__title">
                      <Link to="/Boost-Energy-Efficiency-with-Xylem-Pumps">
                      How Can Xylem Pumps Improve Energy Efficiency in Water Systems? 
                      </Link>
                    </h3>
                    <div className="postbox__text">
                      <p>
                      Water management is an important aspect of infrastructure, industrial operations, and urban development. As cities and industries strive for sustainability, energy efficiency in water systems has become a […]
                      </p>
                    </div>
                    <div className="postbox__read-more">
                      <Link className="tp-btn-border" to="/Boost-Energy-Efficiency-with-Xylem-Pumps">
                        read more
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                </article>


                <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb w-img">
                    <Link to="/Tsurumi-Pumps-Reliable-Wastewater-Solutions">
                      <img src="/assets/img/blog/tsurumi-pump.jpg" alt="tsurumi pump, tsurumi pump distributors, tsurumi pump dealers,tsurumi submersible pump" title="tsurumi pump"/>
                    </Link>
                  </div>
                  <div className="postbox__content">
                    <div className="postbox__meta mb-25">
                      <span>
                        <i className="fal fa-calendar-day"></i>MARCH 15, 2025
                      </span>
                    </div>
                    <h3 className="postbox__title">
                      <Link to="/Tsurumi-Pumps-Reliable-Wastewater-Solutions">
                      How Do Tsurumi Pumps Support Wastewater and Drainage Solutions?
                      </Link>
                    </h3>
                    <div className="postbox__text">
                      <p>
                      Wastewater management is considered to be one of the foundational requirements that need to be sorted in any landscape. The sewage system in any area needed consistent monitoring and repairing over a long period […]
                      </p>
                    </div>
                    <div className="postbox__read-more">
                      <Link className="tp-btn-border" to="/Tsurumi-Pumps-Reliable-Wastewater-Solutions">
                        read more
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                </article>


              </div>
            </div>
            <div className="col-lg-4 col-12">
              <BlogSidebar />
            </div>
          </div>
        </div>
      </div>

      {/* video modal start */}
      <VideoPopup
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={"EW4ZYb3mCZk"}
      />
      {/* video modal end */}
    </>
  );
};

export default BlogPost;
